<template>
  <div class="public-transfer-page">
    <p>跳转中...</p>
  </div>
</template>

<script>
import { isMobile, getQueryValue } from '@/utils/tools';
import { dataInterface } from '@/apis/data';

export default {
  name: 'PublicTransferPage',
  created() {
    const loading = this.$loading({
      text: '跳转中....'
    });
    let isH5 = isMobile();
    // isH5 = true;
    const id = getQueryValue('id');
    const origin = window.location.origin;
    dataInterface({
      __method_name__: 'dataInfo',
      object_uuid: 'object65f255441cc48',
      data_id: id, // 项目ID
      transcode: 0
    }).then(res => {
      if (res.data?.code !== 200 || !res?.data?.data) {
        loading.close();
        this.$message.error('打开失败，内容可能已被删除!');
        window.location.href = `${origin}/login`;
        return false;
      }
      loading.close();
      const data = res.data.data;
      const { h5_path = '', h5_query = {}, pc_query = {} } = data;
      let url = '';
      if (isH5) {
        url = `${origin}${h5_path}?${this.formatUrl(h5_query)}`;
        window.location.href = url;
      } else {
        // url = `${origin}${pc_path}?${this.formatUrl(pc_query)}`;
        // console.log(`${pc_path}?pageUUID=page65f45aff35adf&${this.formatUrl(pc_query)}`, '==22222222')
        // this.$router.replace(`${pc_path}?pageUUID=page65f45aff35adf&${this.formatUrl(pc_query)}`)
        this.$router.replace(`/public-form-page?pageUUID=page65f45aff35adf&${this.formatUrl(pc_query)}`)
      }
    }).catch(err => {
      console.log(err, '---邮件错误!');
      loading.close();
      this.$message.error('出错了，请联系管理员处理!');
      window.location.href = `${origin}/login`;
    });
  },
  methods: {
    formatUrl(obj) {
      if (Object.prototype.toString.call(obj) !== '[object Object]') return '';
      let url = '';
      for (let key in obj) {
        if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
          url += `${key}=${JSON.stringify(obj[key])}` + '}';
        } else {
          url += `${key}=${obj[key]}&`;
        }
      }
      return url.slice(0, url.length - 1);
    }
  }
}
</script>

<style lang="less" scoped>
.public-transfer-page {
  position: relative;
  height: 100%;
  width: 100%;
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>